import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import ImageGallery from "../components/imageGallery"

const IndexPage = (props) => {
    const nodes = props.data.contentfulCarouselOrder.order

    return (
        <Layout>
            <ImageGallery images={nodes} />
        </Layout>
    )   
}

export default IndexPage

export const query = graphql`
query CarouselImages {
  contentfulCarouselOrder {
    order {
      id
      title
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
}
`

export const Head = () => {
    return (
        <>
            <title>BO MIRHOSSENI</title>
        </>
    )
}