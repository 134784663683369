import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

class ImageGallery extends React.Component {

    constructor(props) {
        super(props)

        this.images = props.images
        this.state = { imageShown: 0 }
        
        this.iterateImage = this.iterateImage.bind(this)
    }

    componentDidMount() {
        this.interval = setInterval(this.iterateImage, 3000)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    iterateImage() {
        var i = this.state.imageShown

        if (i < this.images.length - 1) {
            i++
        } else {
            i = 0
        }

        this.setState({ imageShown: i })
    }

    render() {
        const FADE_TIME = 1000
        var i = this.state.imageShown

        var images = this.images.map((node) => (
            <CSSTransition key={node.id} timeout={FADE_TIME} classNames="fade">
                <GatsbyImage image={getImage(node.image.localFile)} alt={node.title} />
            </CSSTransition>
        ))

        return (
            <TransitionGroup component="div" className="carousel">
                {images[i]}
            </TransitionGroup>
        )
    }
}

export default ImageGallery